<template>
  <v-dialog
    v-model="dialogOpen"
    max-width="500"
    persistent
  >
    <v-form v-if="mode !== 'limit-reached'">
      <v-card class="fr-otp-sign-model-1 pt-5">
        <v-card-subtitle>
          <v-row dense justify="center" class="pb-0">
            <v-col cols="12" class="text-center">
              <v-icon id="otp-sign-icon" size="65" color="primary">lock_clock</v-icon>
            </v-col>
            <v-col class="text-center ">
              <h1
                id="otp-sign-title"
                class="fr-identification_model-1__title font-style-title"
                v-html="isAMA ? dataObject.mapping.amaOtpPanelTitle : dataObject.mapping.otpPanelTitle"
              >
              </h1>

              <v-divider/>

              <p
                id="otp-sign-text"
                class="fr-identification_model-1__introduction-text font-style-introduction"
                v-html="isAMA ? dataObject.mapping.amaOtpPanelIntroductionText : dataObject.mapping.otpPanelIntroductionText"
              >
              </p>
            </v-col>
          </v-row>
        </v-card-subtitle>

        <v-card-text class="pb-2">
          <v-row v-if="!isAMA">
            <v-col cols="12" class="text-center pt-0">
              <a
                id="otp-sign-request_code"
                @click="handleRequestNewOtp"
              >
                {{ dataObject.mapping.otpPanelRequestNewCode }}
              </a>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 py-3" justify="center">
            <v-otp-input
              id="otp-sign-input"
              length="6"
              type="number"
              v-model="formValue"
              class="px-1"
            />

          </v-row>
          <v-row dense>
            <v-col class="fr-otp-sign-model-1_error-message">
              <v-alert
                id="otp-sign-error_message"
                v-if="otpError === 'otp_error'"
                dense
                text
                type="error"
                class="ma-0"
                icon="warning"
              >
                {{ dataObject.mapping.otpPanelIncorrectOtp }}
              </v-alert>

              <v-alert
                id="otp-sign-new_code_error_message"
                v-else-if="otpError === 'no_otp_generated'"
                dense
                text
                type="error"
                class="ma-0"
                icon="error"
              >
                {{ dataObject.mapping.otpPanelRequestNewCodeError }}
              </v-alert>

              <v-alert
                id="otp-sign"
                v-else-if="otpError === 'new_otp_generated'"
                dense
                text
                type="success"
                class="ma-0"
                icon="done"
              >
                {{ dataObject.mapping.otpPanelRequestNewCodeSuccess }}
              </v-alert>

              <v-alert
                id="otp-sign"
                v-else-if="otpError === 'otp_limit_reached'"
                dense
                text
                type="error"
                class="ma-0"
                icon="done"
              >
                {{ dataObject.mapping.otpPanelRequestNewCodeLimitError }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="space-around" dense class="ma-0">
            <v-btn
              id="otp-sign-submit"
              :loading="loading"
              class="fr-otp-sign-model-1_accept_button"
              :disabled="!(formValue && formValue.match(/^\d{6}$/))"
              @click="handleSubmit"
            >
              {{ dataObject.mapping.otpPanelOtpSubmit }}
            </v-btn>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-form>

    <v-card v-else class="fr-otp-sign-model-1 pt-5">
      <v-card-subtitle class="text-center">
        <v-alert
          id="otp-sign-code_limit_reached"
          dense
          text
          type="error"
          class="ma-0"
          plain
        >
          {{ dataObject.mapping.otpPanelRequestNewCodeLimitError }}
        </v-alert>

      </v-card-subtitle>
    </v-card>

  </v-dialog>
</template>

<script>
import { getNewOtpSign, sendAMAOtp, sendData } from "@/services/generalService"
import ama_cert from "@/constants/ama_cert"
import { encryptData, loadPublicKey } from "@/utils/cryptoUtils";

export default {
  name: "OtpSignatureManager",
  props: {
    dataObject: Object,
    userActions: Object,

    mode: String,
    isAMA: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      dialogOpen: true,
      formValue: null,
      otpError: null,
      loading: false,
    }
  },

  mounted() {
    const inputs = document.querySelectorAll(".v-otp-input input")
    inputs[0].focus()

    const clearInput = (element) => {
      element.value = ""
      this.formValue = null
      inputs[0].focus()
    }

    inputs.forEach(x => {
      x.addEventListener("click", event => {
        event.preventDefault()
        inputs.forEach(el => clearInput(el))
      })

      x.addEventListener("paste", event => {
        event.preventDefault()
        let code = event.clipboardData.getData('text')
        if (code) {
          code = code.trim()

          if (code.match(/^\d{6}$/)) {
            this.formValue = code
            code = code.split("")
            for (let i = 0; i < code.length; i++) {
              inputs[i].value = code[i]
            }
          }
        }
      })
    })
  },

  methods: {
    async handleSubmit() {
      this.loading = true

      const validResponses = ["document_ok", "signature_correct"]
      let response
      if (this.isAMA) {
        const key = loadPublicKey(ama_cert)
        const encryptedOtpCode = await encryptData(key, this.formValue)
        response = await sendAMAOtp(this.dataObject.currentHash, { code: encryptedOtpCode })
      } else {
        response = await sendData(this.dataObject.currentHash, "documentOtp", { ...this.userActions, ...{ otp: this.formValue } })
      }


      if (response.data.message === "otp_error") {
        this.otpError = "otp_error"
        this.loading = false
      } else if (validResponses.includes(response.data.message)) {
        setTimeout(() => {
          this.loading = false
          this.$emit("signCompleted")
        }, 1500)
      }
    },

    async handleRequestNewOtp() {
      const response = await getNewOtpSign(this.dataObject.currentHash)
      this.otpError = response.data.message
    }
  }

}
</script>

<style>
.fr-otp-sign-model-1_accept_button {
  background-color: var(--v-primary-base) !important;
  color: var(--v-primary-text-base) !important;
}

.fr-otp-sign-model-1 .v-alert__content {
  width: 100%;
  padding-right: 32px !important;
}

@media only screen and (max-width: 915px) {
  .fr-otp-sign-model-1 {
    /* height: 300px !important; */
  }

  .fr-otp-sign-model-1__text {
    font-weight: 400 !important;
    font-size: 0.88rem !important;
  }

  .fr-otp-sign-model-1 > .v-otp-input .v-input__control .v-input__slot {
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
  }

  .fr-otp-sign-model-1 > .v-otp-input .v-input {
    padding: 4px !important;
  }

  .fr-otp-sign-model-1 > .v-text-field--filled > .v-input__control > .v-input__slot,
  .v-text-field--full-width > .v-input__control > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 40px !important;
  }

  .fr-otp-sign-model-1_accept_button {
    width: 200px !important;
    background-color: var(--v-primary-base) !important;
    color: var(--v-primary-text-base) !important;

    font-size: 1.25rem;
    font-weight: 400;
  }

}

</style>